import React from "react"
import '../styles/global.scss'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/Title/"
import GoogleMap from '../components/map'

import CellPhone from "../icons/cell_phone.svg"
import Telephone from "../icons/telephone.svg"
import Email from "../icons/email.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Biuro Rachunkowe Jolanta Saska - Ofirmie | Kraków" />

    <section className="section has-bg-color">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <div className="content">
              <h1 className='title is-1 is-spaced'><Title text="O firmie" /></h1>
              <p>Biuro Rachunkowe Jolanta Saska działa od 2002 roku na podstawie Licencji Ministra Finansów nr 34547/02.</p>
        			<p>Specjalizuje się w obsłudze małych i średnich przedsiębiorstw w zakresie ewidencji księgowych oraz rozliczeń kadr i płac. Biuro ponosi pełną odpowiedzialność za prawidłowość prowadzonych ksiąg i rozliczeń klientów przed US i ZUS. Dodatkowo działalność Biura jest ubezpieczona w zakresie OC.</p>
        			<h2>Rozpoczęcie współpracy</h2>
        			<p>Jeżeli jesteś zainteresowany podjęciem współpracy z naszym biurem rachunkowym prosimy o kontakt telefoniczny lub mailowy w celu podania danych niezbędnych do podpisania umowy na prowadzenie usług księgowych. Podpisanie umowy oraz upoważnień może nastąpić w siedzibie Biura Rachunkowego  lub w siedzibie zleceniodawcy. Biuro Rachunkowe składa wszystkie niezbędne dokumenty w odpowiednim dla Państwa firmy Urzędzie Skarbowym oraz Zakładzie Ubezpieczeń Społecznych.</p>
        			<p>Jeżeli zamierzają Państwo dopiero założyć działalność gospodarczą prosimy o kontakt telefoniczny lub mailowy w celu umówienia się w siedzibie biura rachunkowego na wypełnienie niezbędnych formularzy podatkowych oraz wyboru najlepszej formy opodatkowania dla Państwa nowej firmy.</p>
        			<h3>Comiesięczna współpraca</h3>
        			<p>Po podpisaniu umowy o świadczenie usług księgowych zgłaszają Państwo telefonicznie lub mailowo gotowość do przekazania miesięcznych dowodów księgowych (do 7 dnia każdego miesiąca rozliczeniowego). Dokumenty do biura rachunkowego można dostarczyć osobiście w godzinach 8.00 - 14.00 lub skorzystać z możliwości odbioru dokumentów przez naszego pracownika w siedzibie Państwa firmy. Praktykujemy także dostarczanie dokumentów za pośrednictwem poczty lub firmy kurierskiej,  Po sporządzeniu deklaracji rozliczeniowych zostają Państwo poinformowani o wyniku finansowym, oraz o możliwości odebrania przelewów do US i ZUS lub zostaną one Państwu przesłane w formie papierowej lub elektronicznej.</p>
        			<p><strong>Oszczędzamy Państwa czas!</strong></p>
        			<h3>Przechowywanie dokumentacji księgowej</h3>
        			<ul>
        				<li>Dokumenty księgowe   archiwizowane  i przechowywane  są w siedzibie biura rachunkowego. Po zakończeniu roku podatkowego i sporządzeniu rozliczenia rocznego, dokumentacja zostaje zwrócona podatnikowi.</li>
        				<li>Dokumenty przechowywane mogą być w siedzibie płatnika</li>
        				<li>Wydawane są do biura rachunkowego tylko na czas sporządzania dokumentacji i deklaracji podatkowej a następnie przekazywane są z powrotem do klienta.</li>
        				<li>Na specjalne życzenie istnieje możliwość sporządzania całej dokumentacji w siedzibie zleceniodawcy jednak usługa taka jest dodatkowo płatna.</li>
        			</ul>
        			<h3>Korzyści współpracy</h3>
        			<p>W przypadku  kontroli skarbowej podmiotu obsługiwanego  biuro rachunkowe  reprezentuje płatnika przed Urzędem Skarbowym oraz Zakładem Ubezpieczeń Społecznych. Zleceniodawca informowany jest o zakończeniu kontroli i postanowieniach Urzędu Kontroli Skarbowej.</p>
        			<p>Korzystamy z najnowszej wersji oprogramowania firmy CDN Optima rekomendowanego przez Stowarzyszenie Księgowych w  Polsce.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" className="section contact-section">
      <div className="contact-section__half"></div>
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <div className="contact-section__content has-text-white">
              <h3 className='title is-1 is-spaced has-text-white'><Title text="Kontakt" /></h3>
              <div className="has-text-white contact-info">
                Jolanta Saska - Biuro Rachunkowe <br />
                ul. Vetulaniego 1A / pok. 210 <br />
                31-227 Kraków <br />
                NIP: 945-13-43-698 <br/>
              </div>
              <div className="columns is-multiline">
                <div className="column is-6">
                  <div className="contact-item">
                    <CellPhone width="32px"  height="32px" />
                    <a href="tel:+48502276292">502 276 292</a>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="contact-item">
                    <Telephone width="32px"  height="32px" />
                    <a href="tel:+48124202130">12 420 21 30</a>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="contact-item">
                    <Email width="32px"  height="32px" />
                    <a href="mailto:jolanta.saska@gmail.com">jolanta.saska@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-7">
            <div className="map-container">
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default IndexPage
